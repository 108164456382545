<template>
  <b-card-code :title="file_head">
    <b-overlay 
      :show="show" 
      rounded="sm" 
      no-fade 
    >
      <div :aria-hidden="show ? 'true' : null">
        <validation-observer
          ref="publisherSlotForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="CreateSlotForm">
            <b-row>
              <!-- basic select -->
              <b-col md="6">
                <b-form-group
                    label="Website"
                    label-for="inventory_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="website"
                  >
                    <v-select
                      v-model="inventory_id"
                      :state="errors.length > 0 ? false:null"
                      :reduce="(option) => option.id"
                      :options="inventory_list"
                      @input="inventory_input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Slot Name -->
              <b-col md="6">
                <b-form-group
                    label="Ad Slot Name"
                    label-for="slot_name"
                >
                <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Slot Name"
                    >
                  <b-input-group>
                    <b-form-input 
                      placeholder="Slot name" 
                      v-model="slot_name"
                      :state="errors.length > 0 ? false:null"
                      @input="slotname_input"
                    />                
                    <b-input-group-append is-text>
                      <b-form-checkbox
                        v-model="slot_status"
                        :checked="slot_status"
                        switch
                        inline
                        @input="slotstatus_input"
                    >
                    </b-form-checkbox>
                    </b-input-group-append>                
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Ad Format -->
              <b-col md="6">
                <b-form-group
                    label="Ad Format"
                    label-for="ad-format"
                >
                  <v-select
                    v-model="ad_format"
                    :reduce="(option) => option.id"
                    :options="ad_formats"
                    @input="ad_format_input"
                  />
                </b-form-group>
              </b-col>

              <!-- Slot Position -->
              <b-col md="6">
                <b-form-group
                    label="Slot Position"
                    label-for="slot_positions"
                >
                  <v-select
                    v-model="slot_position"
                    :reduce="(option) => option.id"
                    :options="slot_positions"
                    @input="slot_position_input"
                  />
                </b-form-group>
              </b-col>
              

              <!-- Responsive Type -->
              <b-col md="6">
                <b-form-group
                    label="Responsive Type"
                    label-for="responsive"
                >
                  <v-select
                    v-model="responsive"
                    :reduce="(option) => option.id"
                    :options="responsives"
                    @input="responsive_input"
                  />
                </b-form-group>
              </b-col>

              <!-- Blank space -->
              <b-col md="6">
              </b-col>

              <!-- Desktop Template Template -->
              <b-col md="6">
                <b-form-group
                    label="Desktop Template"
                    label-for="desktop_temp"
                >
                  <v-select
                    :reduce="(option) => option.id"
                    :options="desktop_templates_list"
                    v-model="desktop_temp"
                    @input="desktop_template_input"
                  />
                </b-form-group>
              </b-col>

              <!-- Mobile Template -->
              <b-col md="6">
                <b-form-group
                    label="Mobile Template"
                    label-for="mobile_temp"
                >
                  <v-select
                    v-model="mobile_temp"
                    :reduce="(option) => option.id"
                    :options="mobile_templates_list"
                    @input="mobile_template_input"
                  />
                </b-form-group>
              </b-col>

              <!-- IAB Categories -->
              <b-col md="6">
                <b-form-group
                    label="IAB Categories"
                    label-for="slot_iab_categories"
                >
                  <v-select
                    :reduce="(option) => option.name"
                    :options="iab_categories_list"
                    v-model="slot_iab_categories"
                    multiple
                    :selectable="() => slot_iab_categories.length < 6"
                    @input="updateIABCats"
                  />
                </b-form-group>
              </b-col>

              <!-- IAB Sub Categories -->
              <b-col md="6">
                <b-form-group
                    label="IAB Sub Categories"
                    label-for="slot_iab_sub_categories"
                >              
                  <v-select
                    :reduce="(option) => option.name"
                    :options="iab_sub_categories_list"
                    v-model="slot_iab_sub_categories"
                    multiple
                    :disabled="iabsubdisabled"
                    :selectable="() => slot_iab_sub_categories.length < 12"
                    @input="iabsubcatinput"
                  />
                </b-form-group>
              </b-col>

              <!-- Auction Type -->
              <b-col md="6">
                <b-form-group
                    label="Auction Type"
                    label-for="auction_type"
                >
                  <v-select
                    v-model="auction_type"
                    :reduce="(option) => option.id"
                    :options="auction_types"
                    @input="auction_type_input"
                  />
                </b-form-group>
              </b-col>
              <!-- blank space -->
              <b-col md="6"></b-col>

              <b-col md="6">
                <b-form-textarea
                  label="Desktop Passback"
                  v-model="desktop_pb"
                  placeholder="Desktop Passback"
                  rows="3"
                  :state="desktop_pb.length <= maxChar"
                  class="char-textarea"
                  :class="desktop_pb.length >= maxChar ? 'text-danger' : ''"
                  @input="desktop_pb_input"
                />
                <small
                  class="textarea-counter-desktop_pb float-right"
                  :class="desktop_pb.length >= maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ desktop_pb.length }}</span> / {{ maxChar }}
                </small>
              </b-col>

              <b-col md="6">
                <b-form-textarea
                  label="Mobile Passback"
                  v-model="mobile_pb"
                  placeholder="Mobile Passback"
                  rows="3"
                  :state="mobile_pb.length <= maxChar"
                  class="char-textarea"
                  :class="mobile_pb.length >= maxChar ? 'text-danger' : ''"
                  @input="mobile_pb_input"
                />
                <small
                  class="textarea-counter-mobile_pb float-right"
                  :class="mobile_pb.length >= maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ mobile_pb.length }}</span> / {{ maxChar }}
                </small>
              </b-col>

              <b-col cols="12"></b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid"
                >
                  {{submit_text}}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="reset"
                >
                  Reset
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-overlay>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormValidFeedback,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import { required, integer, between } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      inventory_id:'',
      inventory_list:[],
      slot_name:'',
      slot_status:true,
      ad_format: {label : 'Banner', id:1},
      ad_formats:[
        {label : 'Banner', id:1},
        {label : 'Video', id:2},
        {label : 'Native', id:3}
      ],
      slot_positions:[ 
        {label : 'Unknown', id:1}, 
        {label : 'Above the fold', id:2}, 
        {label : 'Below the fold', id:3},
        {label : 'Header', id:4},
        {label : 'Footer', id:5},
        {label : 'Sidebar', id:6},
        {label : 'Fullscreen', id:7}
      ],
      responsives:[ 
        {label : 'Responsive', id : 1}, 
        {label : 'Desktop Only',id : 2}, 
        {label : 'Mobile Only', id:3} 
      ],
      slot_position:{label : 'Above the fold', id:2},
      responsive:{label : 'Responsive', id : 1},
      desktop_temp: {"id": "1","label": "300×250 Medium Rectangle"},
      desktop_templates_list:[],
      mobile_temp: {"id": "1","label": "300×250 Medium Rectangle"},
      mobile_templates_list:[],
      slot_iab_categories:'',
      iab_categories_list:[],
      slot_iab_sub_categories:'',
      iab_sub_categories_list:[],
      auction_types:[
        {label : 'RTB', id : 1},
        {label : 'Header Bidding',id : 2}
      ],
      auction_type:{label : 'RTB', id : 1},
      desktop_pb: '',
      mobile_pb: '',
      slot_id: null,
      maxChar: 500,
      iabsubdisabled:true,
      file_head:'Create a new slot',
      submit_text:'Submit',
      // validation rules
      required,
      integer,
      between,
      // form submission process handle
      show: false,
      interval: null,
      edited_data:{}
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    reset(){
        console.log('testing reset');
    },
    inventory_input(data){
      this.edited_data.inventory_id=data;
    },
    slotname_input(data){
      this.edited_data.slot_name=data;
    },
    slotstatus_input(data){
      this.edited_data.slot_status=(data===true?1:0);
    },
    ad_format_input(data){
      this.edited_data.ad_format=data;
    },
    slot_position_input(data){
      this.edited_data.slot_position=data;
    },
    responsive_input(data){
      this.edited_data.response=data;
    },
    desktop_template_input(data){
      this.edited_data.desktop_temp=data;
    },
    mobile_template_input(data){
      this.edited_data.mobile_temp=data;
    },
    iabsubcatinput(data){
      this.edited_data.slot_iab_sub_categories=data.join(',');
    },
    auction_type_input(data){
      this.edited_data.auction_type=data;
    },
    desktop_pb_input(data){
      this.edited_data.desktop_pb=data;
    },
    mobile_pb_input(data){
      this.edited_data.mobile_pb=data;
    },    
    updateIABCats(data){
      this.edited_data.iab_categories=data.join(',');
      const accessToken = useJwt.getToken('accessToken')
      this.$http.post('https://api.purplepatch.online/getiabsubcategories', {'iab_categories':data}, {
        headers: {
          Authorization:'Bearer ' + accessToken,
          'x-access-token': accessToken 
        }
      })
      .then(res => {            
        if(res.data){                
          this.iabsubdisabled=false;
          this.iab_sub_categories_list=res.data;
        }
      })
    },
    CreateSlotForm(){
      this.$refs.publisherSlotForm.validate().then(success => {
        if(success){
          this.show = true;
          if(this.slot_id>0){
            this.UpdateFormData(); // after form success, submit form to api
          }else{
            this.SubmitFormData(); // after form success, submit form to api
          }          
        }        
      });
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    SubmitFormData(){
      const accessToken = useJwt.getToken('accessToken');

      this.interval = setInterval(() => {
        let form_new_data = {
          slot_status: true,
          ad_format: 1,
          slot_position: 2,
          responsive: 1,
          desktop_temp: 1,
          mobile_temp: 1,
          auction_type: 1,
          slot_iab_categories: '',
          slot_iab_sub_categories: '',
          desktop_pb: '',
          mobile_pb: ''
        };

        if( this.slot_id !== null ) {
          form_new_data.slot_id=this.slot_id;
        }

        if(this.inventory_id !== '' && typeof this.inventory_id !== 'object' ){
          form_new_data.inventory_id= this.inventory_id;
        }

        if( this.slot_name.length > 0 ){
          form_new_data.slot_name= this.slot_name;
        }

        if( typeof this.ad_format !== 'object' ){
          form_new_data.ad_format= this.ad_format;
        }

        if( typeof this.slot_position !== 'object' ){
          form_new_data.slot_position= this.slot_position;
        }

        if( typeof this.responsive !== 'object' ){
          form_new_data.responsive= this.responsive;
        }

        if( typeof this.desktop_temp !== 'object' ){
          form_new_data.desktop_temp= this.desktop_temp;
        }

        if( typeof this.mobile_temp !== 'object' ){
          form_new_data.mobile_temp= this.mobile_temp;
        }

        if( typeof this.auction_type !== 'object' ){
          form_new_data.auction_type= this.auction_type;
        }

        if( this.slot_iab_categories != '' ){
          form_new_data.slot_iab_categories= this.slot_iab_categories.join(',');
        }

        if( this.slot_iab_sub_categories != '' ){
          form_new_data.slot_iab_sub_categories= this.slot_iab_sub_categories.join(',');
        }

        if( this.desktop_pb != '' ){
          form_new_data.desktop_pb= this.desktop_pb;
        }

        if( this.mobile_pb != '' ){
          form_new_data.mobile_pb= this.mobile_pb;
        }

        console.log(form_new_data);

        this.$http.post('https://api.purplepatch.online/publisher/slot/create/', form_new_data, { 
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            } 
        })
        .then(response => {
          this.show = false;
          this.clearInterval();
          console.log(response);
          if( response.status == '201' ){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: response.data.msg,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.msg
              },
            });
            return router.push({ name: 'publisher-slots' });
          }
        })
        .catch(error => {
          this.show = false;
          this.clearInterval();
          console.log(error);
          // if( error.error && error.status === 400 )
          // {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: 'Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'error',
                  text: 'Something wrong, Try again',
              },
            });
          // }          
        })
      }, 350)

    },
    UpdateFormData ( ) {
     const accessToken = useJwt.getToken('accessToken');

      this.interval = setInterval(() => {
        this.$http.post('https://api.purplepatch.online/publisher/slot/edit/'+router.currentRoute.params.id, this.edited_data, { 
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            } 
        })
        .then(response => {
          this.show = false;
          this.clearInterval();
          if( response.status == '201' ){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: response.data.msg,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.msg
              },
            });
            return router.push({ name: 'publisher-slots' });
          }
        })
        .catch(error => {
          this.show = false;
          this.clearInterval();
          console.log(error);
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: 'Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'error',
                  text: 'Something wrong, Try again',
              },
            });
        })
      }, 350)

    }
  },
  created() {
    const accessToken = useJwt.getToken('accessToken') 

    if(router.currentRoute.params.id){
      this.file_head='Slot Edit Panel';
      this.submit_text='Update';
      // Get edit form data
      this.$http.get('https://api.purplepatch.online/publisher/slot/edit/'+router.currentRoute.params.id, { 
        headers: {
          Authorization:'Bearer ' + accessToken,
          'x-access-token': accessToken 
        } 
      })
      .then(response => {
        let data = response.data;
        this.file_head ='Edit Panel for slot name = ' + data.slot_name;
        this.slot_name=data.slot_name;
        this.slot_id=data.slot_id;
        this.edited_data.slot_id=data.slot_id;
        this.desktop_pb=data.desktop_pb;
        this.slot_status=data.slot_status==1?true:false;

        if(data.slot_iab_categories){
          this.slot_iab_categories=data.slot_iab_categories;
        }

        if(data.slot_iab_categories){
          this.iab_sub_categories_list=data.iab_sub_categories_list;
        }

        if(data.slot_iab_sub_categories){
          this.iabsubdisabled=false;
          this.slot_iab_sub_categories=data.slot_iab_sub_categories;
        }

        if(data.desktop_temp){
          this.desktop_temp=data.desktop_temp;
        } 

        if(data.mobile_temp){
          this.mobile_temp=data.mobile_temp;
        }

        if(data.mobile_pb){
          this.mobile_pb=data.mobile_pb;
        }
        this.inventory_id = data.inventory_id[0];

        if(data.responsive){
          this.responsive = this.responsives[parseInt(data.responsive)-1];
        }
        if(data.ad_format){
          this.ad_format = this.ad_formats[parseInt(data.ad_format)-1];
        }

        if(data.auction_type){
          this.auction_type = this.auction_types[parseInt(data.auction_type)-1];
        }

        if(data.slot_position){
          this.slot_position = this.slot_positions[parseInt(data.slot_position)-1];
        }

        if(data.slot_position){
          this.slot_position = this.slot_positions[parseInt(data.slot_position)-1];
        }

        console.log(data);
      })
      .catch(error => {
        console.log(error);
      })
    }

    // Slot create section      
    this.$http.get('https://api.purplepatch.online/publisher/slot/form', {
      headers: {
        Authorization:'Bearer ' + accessToken,
        'x-access-token': accessToken 
      }
    })
    .then(res => {
      this.iab_categories_list = res.data.iab_categories_list;
      this.desktop_templates_list = res.data.desktop_templates_list;
      this.mobile_templates_list = res.data.mobile_templates_list;
      this.inventory_list = res.data.inventory_list;        
    });
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


